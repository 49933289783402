import React from "react";
import Layout from "../../components/Layout";
// import useFbq from "../../hooks/useFbq";

const ThanksPage = () => {
  // const fbq = useFbq();

  // React.useEffect(() => {
  //   fbq("Contact");
  // }, []);

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Thank you!</h1>
            <p>We'll be in touch with you shortly</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThanksPage;
